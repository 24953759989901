@import "assets/styles/mixins.scss";

.header {
  @include padding-width;
  background: #d9d9d9;

  @media screen and (max-width: 425px) {
    width: calc(100% - 32px);
    background: #d9d9d9;
  }

  .header__wrapper {
    @include flex(space-between);
    width: 100%;
    height: 120px;
    z-index: 100;
    text-decoration: none;

    @media screen and (max-width: 425px) {
      @include flex(space-between);
      width: 100%;
      height: 80px;
      z-index: 100;
      text-decoration: none;
    }

    .wrapper__logo {
      @include flex;

      h2 {
        font-size: 1.3rem;

        @media screen and (max-width: 425px) {
          font-size: 1rem;
        }
      }

      .header__mainlogo {
        width: 120px;

        @media screen and (max-width: 425px) {
          width: 60px;
        }
      }
    }

    .main__container {
      @include flex(space-between);
    }

    .first__container {
      @include flex(space-between, center);
      flex-shrink: 0;
      width: 391px;
      height: 44.29px;

      @media screen and (max-width: 425px) {
        width: 45%;
      }

      a {
        text-decoration: none;
        font-weight: 400;
        font-size: 16px;
        line-height: 19.36px;
        height: 21px;
        padding: 8px;
        color: $black;

        @media screen and (max-width: 425px) {
          text-decoration: none;
          font-weight: 400;
          font-size: 8px;
          line-height: 10px;
          height: 21px;
          padding: 8px;
          color: $black;
        }
      }

      a:hover {
        @include transition-fast;
        box-shadow: 0px 4px 4px 0px (#00000015);
        border-radius: 5px;
        background: #c6c6c6;
      }

      .hover__text {
        display: flex;
        position: absolute;
        margin: 135px 0 0 4px;
        font-size: 15px;
        line-height: 14.52px;
        text-align: center;
      }
    }

    .second__container {
      @include flex(center, center);
      height: 54.29px;
      column-gap: 20px;

      @media screen and (max-width: 425px) {
        display: flex;
        width: 100px;
        height: 28px;
        column-gap: 4px;

        img {
          width: 15px;
          height: 15px;
          mix-blend-mode: multiply;
        }
      }

      a {
        text-decoration: none;
        font-weight: 400;
        font-size: 16px;
        line-height: 19.36px;
        height: 25px;
        padding: 8px;
        color: $black;

        @media screen and (max-width: 425px) {
          text-decoration: none;
          color: $black;
        }

        &:last-child {
          margin-right: -8px;
        }
      }

      a:hover {
        @include transition-fast;
        box-shadow: 0px 4px 4px 0px (#00000015);
        border-radius: 5px;
        background: #c6c6c6;
      }
    }
  }
}

.header__content {
  display: none;
  position: absolute;
  margin-left: -85px;
  width: 200px;
  height: 200px;

  &:hover {
    display: flex;
  }

  .hover__image {
    display: flex;
    position: absolute;
    width: 250px;
    margin-top: 40px;
  }

  .hover__number {
    text-decoration: none;
    display: flex;
    position: absolute;
    margin: 85px 0 0 35px;
    color: #4e4e4e;
    font-size: 12px;
    line-height: 14.52px;
    text-align: center;
  }
}

.header__hover:hover {
  .header__content {
    display: flex;
  }
}
