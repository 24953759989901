@import "assets/styles/mixins.scss";

.dialog__content {
  @include flex(center, center, column);

  .content__input {
    margin: 20px 0;
    height: 50px;
    width: 400px;

    @media screen and (max-width: 425px) {
      width: 70vw;
    }

    .input__label {
      font-size: 17px;

      @media screen and (max-width: 425px) {
        font-size: 14px;
      }
    }

    .input__text_area {
      font-size: 20px;

      @media screen and (max-width: 425px) {
        font-size: 17px;
      }
    }
  }

  .content__button {
    margin-top: 40px;
    width: 50%;
    height: 50px;
    font-size: 1rem;
    font-weight: 600;

    @media screen and (max-width: 425px) {
      margin-top: 20px;
    }
  }
}
