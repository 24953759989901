@import "assets/styles/mixins.scss";

.stages__wrapper {
  display: flex;
  width: 100%;
  @media screen and (max-width: 425px) {
    display: flex;
    width: calc(100% - 32px);
    padding-top: 80px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .stages__container {
    width: 100%;
    height: 372px;
    margin-left: 16.2%;

    @media screen and (max-width: 425px) {
      width: 100%;
      height: 182px;
      margin-left: 0;
    }
  }

  .stages__logo {
    display: flex;
    max-height: 100%;

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  .first__stage {
    width: 114px;
    height: 140px;

    img {
      @media screen and (max-width: 425px) {
        width: 45px;
        height: 60px;
      }

      @media screen and (max-width: 418px) {
        width: 35px;
        height: 50px;
      }
    }

    @media screen and (max-width: 425px) {
      width: 50px;
      height: 120px;
    }

    @media screen and (max-width: 418px) {
      width: 30px;
      height: 100px;
    }

    .first__text {
      display: flex;
      color: #adadad;
      width: 308px;
      height: 29px;
      padding-left: 80px;
      margin-top: -140px;
      flex-shrink: 0;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 300;
      line-height: normal;

      @media screen and (max-width: 425px) {
        display: flex;
        color: #adadad;
        width: 150px;
        height: 40px;
        padding-left: 50px;
        margin-top: -65px;
        flex-shrink: 0;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      @media screen and (max-width: 418px) {
        margin-top: -54px;
        font-size: 0.95rem;
      }
    }
  }

  .second__stage {
    width: 114px;
    height: 140px;
    padding-left: 124px;

    img {
      @media screen and (max-width: 425px) {
        width: 45px;
        height: 60px;
      }

      @media screen and (max-width: 418px) {
        width: 35px;
        height: 50px;
      }
    }

    @media screen and (max-width: 425px) {
      width: 114px;
      height: 140px;
      padding-left: 67px;
      margin-top: -75px;
    }

    @media screen and (max-width: 418px) {
      width: 94px;
      height: 120px;
      padding-left: 48px;
      margin-top: -65px;
    }

    .second__text {
      width: 382px;
      height: 29px;
      padding-left: 126px;
      margin-top: -140px;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      flex-shrink: 0;
      color: #adadad;

      @media screen and (max-width: 425px) {
        width: 190px;
        height: 29px;
        padding-left: 59px;
        margin-top: -65px;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        flex-shrink: 0;
        color: #adadad;
      }

      @media screen and (max-width: 418px) {
        margin-top: -54px;
        font-size: 0.95rem;
      }
    }
  }

  .third__stage {
    width: 114px;
    height: 140px;
    padding-left: 305px;

    img {
      @media screen and (max-width: 425px) {
        width: 45px;
        height: 60px;
      }

      @media screen and (max-width: 418px) {
        width: 35px;
        height: 50px;
      }
    }

    @media screen and (max-width: 425px) {
      width: 114px;
      height: 140px;
      padding-left: 140px;
      margin-top: -95px;
    }

    @media screen and (max-width: 418px) {
      width: 94px;
      height: 120px;
      padding-left: 100px;
      margin-top: -75px;
    }

    .third__text {
      position: absolute;
      width: 383px;
      height: 58px;
      padding-left: 126px;
      margin-top: -140px;
      flex-shrink: 0;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      color: #adadad;

      @media screen and (max-width: 425px) {
        position: absolute;
        width: 200px;
        height: 58px;
        padding-left: 56px;
        margin-top: -65px;
        flex-shrink: 0;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: #adadad;
      }

      @media screen and (max-width: 418px) {
        font-size: 0.95rem;
      }
    }
  }
}
