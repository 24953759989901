@import "assets/styles/mixins.scss";

.price__wrapper {
  margin-bottom: 100px;
  width: 100%;

  @media screen and (max-width: 425px) {
    margin-bottom: 0;
    width: 100%;
    height: 100%;
  }

  .main__container {
    @include padding-width;
    justify-content: space-between;
    height: 500px;
    margin-top: 77px;

    @media screen and (max-width: 425px) {
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-top: 37px;
      width: calc(100% - 32px);
      height: 100%;
    }
  }

  .price__heading {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 77px;
    width: 100%;
    height: 44px;
    color: #242b5a;
    font-weight: 600;
    font-size: 2.25rem;

    @media screen and (max-width: 425px) {
      margin-top: 30px;
      font-size: 2rem;
    }
  }

  .price__card {
    @include flex(space-around, center, column);
    @include transition-very-slow;
    height: 480px;
    width: 320px;
    box-shadow:
      0 1px 4px rgba(0, 0, 0, 0.3),
      -23px 0 20px -23px rgba(0, 0, 0, 0.6),
      23px 0 20px -23px rgba(0, 0, 0, 0.6),
      inset 0 0 40px rgba(0, 0, 0, 0.1);
    cursor: pointer;

    @media screen and (max-width: 425px) {
      @include flex(space-around, center, column);
      margin-bottom: 40px;
      height: 100vw;
      width: 90%;
    }

    &:hover {
      transform: scale(1.1);
    }

    .price__cost {
      text-align: center;
      font-weight: 600;
      font-size: 1.5rem;

      @media screen and (max-width: 425px) {
        font-size: 1.6rem;
      }

      span {
        font-weight: 400;
        font-size: 1rem;

        @media screen and (max-width: 425px) {
          font-weight: 400;
          font-size: 1rem;
        }
      }
    }

    .price__image {
      width: 67%;
      height: 54.2%;
      box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
      filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.3));

      @media screen and (max-width: 425px) {
        width: 53%;
        height: 53%;
        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
        filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.3));
      }
    }

    .heads__first {
      width: 300px;
      height: 47px;
      font-weight: 600;
      font-size: 1.5rem;

      @media screen and (max-width: 425px) {
        width: 80%;
      }
    }
  }
}
