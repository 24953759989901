@import "assets/styles/mixins.scss";

.tech__container {
  width: 100%;
  height: 381px;
  padding-top: 63px;

  @media screen and (max-width: 425px) {
    width: 100%;
    height: 151px;
    padding-top: 30px;
  }

  .panel__up {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 111px;
    rotate: (180deg);
    background: linear-gradient(180deg, rgba(46, 46, 46, 0.27) 0%, rgba(102, 102, 102, 0) 50%);

    @media screen and (max-width: 425px) {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 51px;
      background: linear-gradient(180deg, rgba(46, 46, 46, 0.27) 0%, rgba(102, 102, 102, 0) 50%);
    }
  }

  .panel__down {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 111px;
    background: linear-gradient(180deg, rgba(46, 46, 46, 0.27) 0%, rgba(102, 102, 102, 0) 50%);

    @media screen and (max-width: 425px) {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 51px;
      background: linear-gradient(180deg, rgba(46, 46, 46, 0.27) 0%, rgba(102, 102, 102, 0) 50%);
    }
  }

  .text__process {
    @include flex(center, center);
    width: 100%;
    height: 115px;

    @media screen and (max-width: 425px) {
      width: 100%;
      height: 75px;
    }

    p {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 648px;
      height: 82px;
      padding-left: 396px;
      padding-right: 396px;
      font-size: 1.5rem;
      font-weight: 500;
      line-height: normal;
      font-style: normal;
      color: $black;
      text-align: center;

      @media screen and (max-width: 425px) {
        width: 300px;
        height: 82px;
        padding-left: 50px;
        padding-right: 50px;
        font-size: 0.95rem;
        font-weight: 500;
        line-height: normal;
        font-style: normal;
        color: $black;
        text-align: center;
      }
    }
  }

  .logo__up {
    margin-top: -9px;
    width: 213px;
    height: 136px;
    rotate: (180deg);

    @media screen and (max-width: 425px) {
      margin-top: -3px;
      width: 113px;
      height: 66px;
      rotate: (180deg);
    }
  }

  .logo__down {
    width: 213px;
    height: 136px;
    margin-top: -9px;

    @media screen and (max-width: 425px) {
      width: 113px;
      height: 66px;
      margin-top: -5px;
    }
  }
}
