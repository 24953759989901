@import "assets/styles/mixins.scss";

.contact {
  display: block;
  width: 100%;

  @media screen and (max-width: 425px) {
    display: block;
    width: 100%;
  }

  .contact__toppanel {
    position: absolute;
    width: 100%;
    height: 111px;
    margin-top: 123px;
    background: linear-gradient(180deg, rgba(89, 89, 89, 0.18) 0%, rgba(102, 102, 102, 0) 50%);

    @media screen and (max-width: 425px) {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 51px;
      margin-top: 0px;
      background: linear-gradient(180deg, rgba(46, 46, 46, 0.27) 0%, rgba(102, 102, 102, 0) 50%);
    }
  }

  .contact__botpanel {
    position: absolute;
    width: 100%;
    height: 67px;
    margin-top: -67px;
    background: linear-gradient(180deg, rgba(89, 89, 89, 0.18) 0%, rgba(102, 102, 102, 0) 50%);
    transform: rotate(180deg);

    @media screen and (max-width: 425px) {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 50px;
      margin-top: -31px;
      background: linear-gradient(180deg, rgba(46, 46, 46, 0.27) 0%, rgba(102, 102, 102, 0) 50%);
    }
  }

  .contact__text {
    margin-left: 25%;
    margin-top: 182px;
    width: 15%;
    height: 20%;
    font-size: 0.938rem;
    z-index: 2;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: $black;

    @media screen and (max-width: 1440px) {
      margin-left: 30%;
    }

    @media screen and (max-width: 1024px) {
      margin-left: 10%;
    }

    @media screen and (max-width: 425px) {
      margin-left: 16px;
      margin-top: 40px;
      width: 15%;
      font-size: 1rem;
      z-index: 5;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: $black;
    }

    h2 {
      display: flex;
      font-size: 2rem;
      font-style: normal;

      @media screen and (max-width: 425px) {
        display: flex;
        margin-top: 0;
        font-size: 1.2rem;
        font-style: normal;
      }
    }

    a {
      display: flex;
      margin-top: 20px;
      font-size: 1.1rem;
      text-decoration: none;
      color: $black;

      @media screen and (max-width: 425px) {
        display: flex;
        margin-top: 5px;
        font-size: 0.8rem;
        text-decoration: none;
        color: $black;
      }
    }

    address {
      font-size: 0.938rem;
      line-height: 1.134rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      a {
        width: 240px;

        @media screen and (max-width: 425px) {
          width: 140px;
          margin: 0 0 10px 200px;
          font-size: 0.8rem;
          line-height: 1.1rem;
        }
      }

      h2 {
        @media screen and (max-width: 425px) {
          width: 140px;
          margin-left: 200px;
          margin-top: -80px;
        }
      }
    }

    .contact__mail {
      display: flex;
      width: 220px;

      @media screen and (max-width: 425px) {
        margin-top: 20px;
        width: 140px;
      }
    }
  }

  .contact__media {
    @include flex(flex-start, center);

    @media screen and (max-width: 425px) {
      @include flex(space-around, center);
      padding-left: 200px;
    }

    a {
      padding: 8px;
      color: $black;

      @media screen and (max-width: 425px) {
        display: flex;
        margin-right: 15px;
        padding: 0;
        color: $black;

        img {
          width: 20px;
          height: 20px;
        }
      }
    }

    a:hover {
      @include transition-fast;

      border-radius: 5px;
      background: #c6c6c6;
    }
  }

  .contact__shadowbar1 {
    position: absolute;
    display: flex;
    margin-left: -332px;
    margin-top: 346px;
    width: 556px;
    height: 111px;
    background: linear-gradient(180deg, rgba(89, 89, 89, 0.18) 0%, rgba(102, 102, 102, 0) 45%);
    transform: rotate(90deg);

    @media screen and (max-width: 425px) {
      display: none;
    }
  }

  .contact__shadowbar2 {
    position: absolute;
    display: flex;
    margin-left: 32px;
    margin-top: -335px;
    width: 556px;
    height: 111px;
    background: linear-gradient(180deg, rgba(89, 89, 89, 0.18) 0%, rgba(102, 102, 102, 0) 45%);
    transform: rotate(-90deg);

    @media screen and (max-width: 425px) {
      display: none;
    }
  }

  .contact__wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media screen and (max-width: 425px) {
      display: flex;
      justify-content: justify;
      width: 100%;
    }

    .contact__sidelogo {
      position: absolute;

      @media screen and (max-width: 1024px) {
        display: none;
      }
    }
  }

  .contact__panel {
    margin-right: 10%;
    width: 25%;

    @media screen and (max-width: 425px) {
      margin: 0 0 0 -15%;
      width: 100%;
    }

    iframe {
      position: relative;
      width: 100%;
      max-width: 400px;
      height: 554px;
      margin-top: 123px;
      z-index: 1;
      border-style: none;

      @media screen and (max-width: 425px) {
        display: flex;
        justify-content: center;
        margin-top: 180px;
        width: calc(100vw - 32px);
        height: 234px;
      }

      .gmnoprint {
        @media screen and (max-width: 425px) {
          display: none;
        }
      }
    }
  }

  .contact__telefon {
    display: flex;
    width: 146px;
    @media screen and (max-width: 425px) {
      width: 94px;
    }
  }
}
