@import "assets/styles/mixins.scss";

.end {
  @include padding-width;
  justify-content: space-between;
  max-width: 100%;
  height: 468px;
  background: #d9d9d9;

  @media screen and (max-width: 425px) {
    width: calc(100% - 32px);
    justify-content: space-between;
    max-width: 100%;
    height: 208px;
    background: #d9d9d9;
  }

  .end__toppanel {
    position: absolute;
    width: 100%;
    height: 87px;
    margin-left: -160px;
    margin-top: -380px;
    background: linear-gradient(180deg, rgba(89, 89, 89, 0.18) 0%, rgba(102, 102, 102, 0) 50%);

    @media screen and (max-width: 425px) {
      height: 40px;
      margin-left: -17px;
      margin-top: -170px;
    }
  }

  .end__description {
    @include flex(space-between, center);
    width: 100%;
    height: 70%;

    .end__logo {
      align-items: center;

      @media screen and (max-width: 425px) {
        display: none;
      }

      img {
        @media screen and (max-width: 425px) {
          width: 70px;
          height: 40px;
        }
      }

      p {
        padding-left: 93px;

        @media screen and (max-width: 425px) {
          font-size: 9px;
          padding-left: 25px;
          margin-top: 3px;
        }
      }
    }

    .end__title {
      display: flex;
      align-items: center;
      flex-flow: column;

      @media screen and (max-width: 425px) {
        justify-content: space-between;
        height: 100%;
        width: 130px;
      }

      .title__socials {
        @include flex(center, center, column);
        width: 100%;

        p {
          font-size: 12px;
          line-height: 14.52px;

          @media screen and (max-width: 425px) {
            margin: 8px 0 10px;
            line-height: 10px;
          }
        }

        .end__logotypes {
          display: flex;
          justify-content: center;
          width: 83.23px;
          height: 54.29px;
          fill-opacity: 0;

          @media screen and (max-width: 425px) {
            width: auto;
            height: auto;
          }

          a {
            width: 40px;
            height: 25px;
            padding: 8px;
            color: $black;

            @media screen and (max-width: 425px) {
              width: 20px;
              height: 20px;
            }

            img {
              @media screen and (max-width: 425px) {
                width: 20px;
                height: 20px;
              }
            }
          }
        }
      }

      .end__rights {
        padding-top: 100px;

        @media screen and (max-width: 425px) {
          margin-top: 2px;
          margin-left: 5px;
          padding-top: 0px;
          text-align: center;
          font-size: 8px;
        }
      }

      a:hover {
        @media screen and (min-width: 770px) {
          @include transition-fast;
          box-shadow: 0px 4px 4px 0px (#00000015);
          border-radius: 5px;
          background: #c6c6c6;
        }
      }
    }

    .end__column {
      display: flex;
      align-content: center;
      flex-direction: column;
      width: 196px;
      height: 200px;

      @media screen and (max-width: 425px) {
        margin-top: -50px;
        margin-right: 5%;
        z-index: 5;
        width: 120px;
        height: 105px;
      }

      .end__mail {
        width: 170px;

        @media screen and (max-width: 425px) {
          width: auto;
        }
      }

      .end__phone {
        width: 112px;

        @media screen and (max-width: 425px) {
          width: auto;
        }
      }

      h2 {
        color: $black;
        font-weight: 500;

        @media screen and (max-width: 425px) {
          font-size: 14px;
        }
      }

      a {
        text-decoration: none;
        color: $black;
        font-weight: 400;
        font-size: 12px;
        cursor: pointer;

        @media screen and (max-width: 425px) {
          font-size: 8px;
        }
      }

      address {
        text-decoration: none;
        color: $black;
        font-weight: 400;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        @media screen and (max-width: 425px) {
          font-size: 8px;
        }
      }
    }
  }
}
