@import "assets/styles/mixins.scss";

.opinions__wrapper {
  @include padding-width;
  @include flex(flex-start, flex-start, column);
  max-width: 100%;
  height: 672px;

  @media screen and (max-width: 425px) {
    @include flex(flex-start, flex-start, column);
    width: calc(100% - 32px);
    max-width: 100%;
    height: auto;
  }

  @media screen and (max-width: 390px) {
    width: calc(100% - 35px);
    @include flex(flex-start, flex-start, column);
    max-width: 100%;
    height: 302px;
  }

  header {
    margin: 60px 0;
    font-weight: 400;
    font-size: 2rem;

    @media screen and (max-width: 425px) {
      margin: 50px 0 0 0;
      width: 100%;
      text-align: center;
      font-weight: 400;
      font-size: 1.5rem;
    }
  }

  .opinions__main {
    display: flex;
    justify-content: space-around;
    width: 100%;

    @media screen and (max-width: 425px) {
      display: flex;
      justify-content: space-around;
      width: 100%;
    }

    .carousel__wrapper {
      width: 100%;

      .next__arrow {
        height: 55px;

        @media screen and (max-width: 425px) {
          height: 35px;
        }
      }

      .prev__arrow {
        @extend .next__arrow;
        transform: rotate(180deg);
      }

      .collection__wrapper {
        @include flex(space-around, center);
        margin: 0 40px;
        height: 100%;

        .wrapper__common {
          @include flex(flex-start, flex-end);

          .opinions__person {
            margin: 0 -15px 15px 0;
            width: 48px;
            height: 48px;

            @media screen and (max-width: 425px) {
              margin: 0 -7px 7px 0;
              width: 28px;
              height: 28px;
            }
          }

          .wrapper__card {
            height: 330px;
            width: 290px;
            background: url("../../assets/images/dialogWindow.png") no-repeat;
            background-size: contain;

            @media screen and (max-width: 425px) {
              @include flex(center, center, column);
              width: 180px;
              height: 204px;
            }

            .opinions__user {
              margin: 20px 0 0;
              padding-right: 30px;
              text-align: right;
              font-size: 1rem;
              font-weight: 600;
              line-height: 0.8rem;
              color: #db400d;

              @media screen and (max-width: 425px) {
                margin: 0;
                padding-right: 20px;
                width: 90%;
                text-align: right;
                font-size: 0.5rem;
                line-height: 0.7rem;
              }
            }

            .opinions__date {
              @extend .opinions__user;
              margin-top: 10px;
              font-size: 0.8rem;
              font-weight: 400;

              @media screen and (max-width: 425px) {
                margin-top: 0;
                font-size: 0.4rem;
              }
            }

            .opinion__desctiption {
              @include flex(center, center);
              width: 100%;
              height: 260px;

              @media screen and (max-width: 425px) {
                height: 80%;
              }

              .opinions__text {
                margin: 0;
                padding: 0 20px 0 40px;
                font-size: 0.92rem;
                color: $black;

                @media screen and (max-width: 425px) {
                  padding: 0 20px 0 30px;
                  font-size: 0.7rem; 
                  color: $black;
                }
              }
            }
          }
        }
      }
    }
  }
}
