@import "assets/styles/mixins.scss";

.loader {
  @include flex(center, center, column);
  z-index: 9;
  height: 100%;
  width: 100%;
  text-align: center;
  background-color: $white;

  .loader__spinner {
    width: 350px;
    height: 350px;

    @media screen and (max-width: 425px) {
      width: 150px;
      height: 150px;
    }
  }
}
