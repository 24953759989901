@import "assets/styles/mixins.scss";

.types__wrapper {
  @include flex(center, flex-start, column);
  width: 100%;
  min-height: 807px;
  background: url("../../assets/images/types.png") no-repeat;
  background-position: center;
  background-size: cover;

  @media screen and (max-width: 425px) {
    width: 100%;
    min-height: 400px;
  }

  .wrapper__content {
    @include padding-width;
    justify-content: space-between;
    align-items: flex-start;
    height: 807px;
    background: rgba(1, 1, 1, 0.5);

    @media screen and (max-width: 425px) {
      flex-direction: column;
      height: auto;
    }

    .content__point {
      @include flex(flex-start, flex-start, column);
      margin-left: 15%;
      height: 328px;
      width: 400px;
      text-align: justify;
      font-weight: 400;
      font-size: 1.5rem;
      line-height: 29.05px;
      color: $white;

      @media screen and (max-width: 1024px) {
        margin-left: 0;
      }

      @media screen and (max-width: 425px) {
        width: 100%;
        height: auto;
      }

      h1 {
        margin-top: 120px;
        max-width: 357px;
        width: 360px;
        font-weight: 400;
        font-size: 3.4rem;
        line-height: 60px;
        color: $white;

        @media screen and (max-width: 425px) {
          margin: 25px 0 0 0;
          font-size: 2.5rem;
        }
      }

      ul {
        margin-top: 150px;
        padding-left: 20px;
        width: 500px;

        @media screen and (max-width: 425px) {
          margin: 20px 0 0;
          width: calc(100% - 20px);
        }

        li {
          margin-bottom: 37px;
          width: 600px;
          font-size: 1.7rem;

          @media screen and (max-width: 425px) {
            margin-bottom: 15px;
            width: 90%;
            font-size: 1.3rem;
            word-break: break-word;

            &:last-child{
              max-width: 220px;
            }
          }
        }
      }
    }

    .content__button {
      display: flex;
      margin-right: 15%;

      @media screen and (max-width: 1024px) {
        margin-right: 0;
      }

      @media screen and (max-width: 425px) {
        @include flex(center, flex-start);
        width: 100%;
      }

      a {
        text-decoration: none;
      }

      button {
        @include flex();
        width: 310px;
        height: 120px;
        margin-top: 86px;
        z-index: 5;
        border-radius: 20px;
        background: rgba(255, 255, 255, 0.05);
        opacity: 0.75;
        font-size: 35px;
        color: $white;
        cursor: pointer;

        @media screen and (max-width: 425px) {
          margin: 30px 0 50px;
          width: 200px;
          height: 80px;
          font-size: 1.8rem;
        }

        &:hover {
          opacity: 1.1;
          box-shadow: 10px 10px 15px #00000040;
          color: $white;
        }
      }

      .types__shadow {
        width: 340px;
        height: 145px;
        margin-top: 82px;
        margin-left: -315px;
        border-radius: 20px;

        @media screen and (max-width: 425px) {
          display: none;
        }
      }
    }
  }
}
