@import "assets/styles/mixins.scss";

.color {
  @include padding-width;
  justify-content: center;

  img {
    width: 85%;

    @media screen and (max-width: 425px) {
      margin-bottom: 30px;
      width: 100%;
    }
  }
}
