@import "assets/styles/mixins.scss";

.not__found {
  @include flex;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 143px);

  @media screen and (max-width: 425px) {
    height: calc(100vh - 128px);
  }

  h2 {
    font-size: 4em;
    font-weight: 600;
    color: $text;
  }
}
