@import "assets/styles/mixins.scss";

.block__wrapper {
  @include flex(flex-start, flex-start, column);
  width: 100%;
  min-height: 740px;
  background: url("../../assets/images/mainpic.png") no-repeat;
  background-size: cover;
  background-attachment: fixed;

  @media screen and (max-width: 425px) {
    @include flex(flex-end, center, column-reverse);
    min-height: auto;
  }

  .wrapper__button {
    @include flex(flex-end);
    width: 100%;

    @media screen and (max-width: 425px) {
      @include flex(center);
      width: 100%;
    }

    button {
      margin: 85px 17% 50px 0;
      width: 310px;
      height: 120px;
      border-radius: 20px;
      background: rgba(255, 255, 255, 0.05);
      opacity: 0.75;
      cursor: pointer;

      @media screen and (max-width: 425px) {
        margin: 50px 0;
        width: 200px;
        height: 80px;
      }

      &:hover {
        opacity: 1.1;
        filter: drop-shadow(10px 10px 15px rgba(0, 0, 0, 0.3));
      }

      p {
        display: flex;
        justify-content: center;
        padding: 36px 0;
        margin: 0;
        font-size: 35px;
        color: $white;
        text-decoration: none;

        @media screen and (max-width: 425px) {
          display: flex;
          padding-top: 17px;
          padding-bottom: 17px;
          font-size: 25px;
          color: $white;
          text-decoration: none;
        }
      }
    }
  }

  .wrapper__description {
    margin-left: 17%;
    color: $white;
    max-width: 860px;

    @media screen and (max-width: 425px) {
      margin-left: 4%;
      color: $white;
    }

    h1 {
      font-weight: 400;
      color: $white;

      @media screen and (max-width: 425px) {
        margin-top: 30px;
        margin-right: 40px;
      }
    }

    p {
      margin: 0;
      font-size: 1.15rem;
      font-weight: 400;

      @media screen and (max-width: 425px) {
        margin-right: 20px;
        font-size: 0.7rem;
      }
    }

    .description__bottom {
      @include flex(flex-start, center);
      margin-top: 63px;

      @media screen and (max-width: 425px) {
        @include flex(flex-start, center);
        margin-top: 17px;
      }

      p {
        margin-left: 75px;
        width: 355px;

        @media screen and (max-width: 425px) {
          margin-left: 45px;
          width: 105px;
        }
      }
    }
  }
}

.button__shadow {
  width: 340px;
  height: 145px;
  margin-right: -335px;
  margin-top: 55px;
  border-radius: 20px;

  @media screen and (max-width: 425px) {
    display: none;
  }
}
