// Colors

$back: rgb(5, 15, 30);
$text: #a5a5a5;
$text-lighten: #c0bdd0;
$grey: #d9d9d9;
$black: #000000;
$black-lighten: #1d1d1d;
$blue: #08f;
$gray: #d2d9e5;
$gray-back: #f2f2f2;
$green: #388603;
$pink: rgb(255, 87, 106);
$pink-dark: rgb(196, 64, 79);
$white: #ffffff;

// Transitions
@mixin transition-fast() {
  transition: all 0.05s ease-in-out;
}
@mixin transition-middle() {
  transition: all 0.1s ease-in-out;
}
@mixin transition-slow() {
  transition: all 0.3s ease-in-out;
}
@mixin transition-very-slow() {
  transition: all 0.5s ease-in-out;
}
@mixin transition-color() {
  transition: color 0.2s ease-in-out;
}

//flex-mixins
@mixin flex($position: space-around, $align: center, $direction: row) {
  display: flex;
  flex-direction: $direction;
  justify-content: $position;
  align-items: $align;
}

@mixin padding-width() {
  @include flex(center);

  padding-left: 160px;
  padding-right: 160px;
  width: calc(100% - 320px);

  @media screen and (max-width: 1280px) {
    padding-left: 80px;
    padding-right: 80px;
    width: calc(100% - 160px);
  }

  @media screen and (max-width: 768px) {
    padding-left: 16px;
    padding-right: 16px;
    width: calc(100% - 32px);
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
