@import "assets/styles/mixins.scss";

.mobile__menu {
  position: relative;

  a {
    text-decoration: none;
  }

  .menu__button {
    position: relative;
    padding: 0;
    margin: 10px 5px;
    height: 20px;
    width: 30px;
    background: none;
    border: none;
    z-index: 19;
    cursor: pointer;

    .line__top {
      @include transition-middle;
      position: absolute;
      top: 0px;
      width: 30px;
      border-bottom: 2px solid $white;
    }

    .line__top__signup {
      border-bottom: 2px solid $black;
    }

    .line__top__active {
      top: 10px;
      border-bottom: 2px solid $black;
      transform: rotate(45deg);
    }

    .line__center {
      @extend .line__top;
      top: 10px;
    }

    .line__center__signup {
      border-bottom: 2px solid $black;
    }

    .line__center__active {
      display: none;
    }

    .line__bottom {
      @extend .line__top;
      top: 20px;
    }

    .line__bottom__signup {
      border-bottom: 2px solid $black;
    }

    .line__bottom__active {
      top: 10px;
      border-bottom: 2px solid $black;
      transform: rotate(-45deg);
    }
  }

  .menu__navigation {
    display: none;
  }

  .menu__navigation__active {
    position: absolute;
    top: -37px;
    right: -16px;
    width: 100vw;
    height: 100vh;
    padding-top: 100px;
    background-color: $white;
    border: 1px solid $white;
    z-index: 9;
    touch-action: none;
    animation: fadein 0.5s ease-in-out;

    .navigation__line {
      @include flex;
      justify-content: space-between;
      height: 59px;
      margin: 0 35px 0 25px;
      border-bottom: 1px solid $gray;

      h3 {
        margin: 0;
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        color: $black;
      }

      img {
        height: 15px;
        width: 8px;
        color: $pink;
      }
    }
  }
}
