@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  outline: none !important;
}

html {
  scroll-behavior: smooth;
  font-size: 14px;

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
}

a {
  cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

button:focus {
  outline: none;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;

  margin: 0;

  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100%;
}
