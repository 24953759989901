@import "assets/styles/mixins.scss";

.text__wrapper {
  @include padding-width;
  background: #fff;

  @media screen and (max-width: 425px) {
    width: calc(100% - 32px);
    background: #fff;
  }

  .wrapper__description {
    width: 1440px;
    height: 766px;

    @media screen and (max-width: 425px) {
      width: 100%;
      height: 100%;
    }
  }

  header {
    @include flex;
    padding-top: 90px;
    height: 88px;
    font-size: 128px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: rgba(0, 0, 0, 0.7);

    @media screen and (max-width: 425px) {
      @include flex;
      width: 100%;
      justify-content: center;
      padding-top: 35px;
      height: 88px;
      font-size: 48px;
      font-style: normal;
      font-weight: 400;

      color: rgba(0, 0, 0, 0.7);
    }
  }

  .text__heading {
    margin: 30px 0 0 20%;
    width: 500px;
    height: 56px;
    text-align: justify;
    font-size: 1.15rem;
    font-weight: 300;
    color: #a67000;

    @media screen and (max-width: 425px) {
      margin-top: -10px;
      margin-left: 5%;
      width: 90%;
      height: 36px;
      text-align: center;
      font-size: 0.8rem;
      color: #a67000;
    }
  }
}

.column__wrapper {
  @include flex(space-between);
}

.column {
  @include flex(flex-start, center, column);
  margin-top: 100px;
  width: 285px;
  height: 404px;
  background: url("../../assets/images/gradientcolumn.png") no-repeat;

  @media screen and (max-width: 425px) {
    margin-top: 30px;
    width: 31%;
    height: auto;
  }

  img {
    margin-top: -20px;

    @media screen and (max-width: 425px) {
      margin-top: -12px;
      width: 30px;
    }
  }

  h3 {
    justify-content: space-between;
    font-size: 20px;
    line-height: 24.2px;
    font-weight: 600;
    text-align: center;
    text-decoration: underline;

    @media screen and (max-width: 425px) {
      font-size: 8px;
      line-height: 10px;
      font-weight: 600;
    }
  }

  p {
    width: 178px;
    height: 210px;
    font-size: 12px;
    line-height: 14.52px;
    word-spacing: 5px;
    hyphens: auto;

    @media screen and (max-width: 425px) {
      width: 80%;
      height: 130px;
      font-size: 0.54rem;
      font-weight: 500;
      line-height: 0.6rem;
      word-spacing: 0.5px;
    }
  }
}
