@import "assets/styles/mixins.scss";

.wheel__main {
  @include padding-width;
  background: url("../../assets/images/mainWheel.png") no-repeat;
  background-attachment: fixed;
  background-size: 100%;

  @media screen and (max-width: 425px) {
    width: calc(100% - 32px);
    background: url("../../assets/images/mainWheel.png") repeat;
  }

  .wheel__container {
    width: 100%;

    @media screen and (max-width: 425px) {
      width: 100%;
    }

    .wheel__jobs {
      @include flex(center);
      margin-top: 70px;
      font-size: 3rem;
      font-style: normal;
      font-weight: 500;
      color: $white;

      @media screen and (max-width: 425px) {
        margin-top: 30px;
        font-size: 2.5rem;
      }
    }

    .wheel__images {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      padding-top: 34px;
      padding-bottom: 115px;

      @media screen and (max-width: 425px) {
        padding-bottom: 34px;

        &:last-child {
          justify-content: center;
        }
      }

      .wheel__wrapper {
        @include flex(center);
        width: 15vw;
        height: 15vw;

        @media screen and (max-width: 425px) {
          height: 47vw;
          width: 50%;
        }

        .wheel__picture {
          @include transition-very-slow;
          width: 11vw;
          height: 11vw;
          box-shadow: (5px 5px 5px 0px rgba(0, 0, 0, 0.5));
          cursor: pointer;

          @media screen and (max-width: 425px) {
            width: 90%;
            height: auto;
          }

          &:hover {
            transform: scale(1.4);

            @media screen and (max-width: 425px) {
              transform: scale(1.1);
            }
          }
        }
      }
    }
  }
}
